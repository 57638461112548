import logo from "../assets/logo.webp";
import { tesla } from "../assets";
import { starbucks } from "../assets";
export const experienceData = {
  catala: {
    title: "Què he fet fins ara",
    h2: "Experiència laboral",
    experiences: [
      {
        title: "Tècnic Economista",
        company_name: "T19 Estudis i Projectes S.L",
        icon: starbucks, // Assegura't de definir "starbucks" com a icona si és necessari
        iconBg: "#383E56",
        date: "Gener 2019 - Agost 2022",
        points: [
          "Seguiment pressupostari",
          `Col·laboració en el projecte "Apropa Cultura" a l'àrea de projecció econòmica i sostenibilitat`,
        ],
      },
      {
        title: "Bootcamp",
        company_name: "Barcelona Activa",
        icon: tesla, // Assegura't de definir "tesla" com a icona si és necessari
        iconBg: "#E6DEDD",
        date: "Febrer 2022 - Setembre 2022",
        points: [
          "Desenvolupar i mantenir aplicacions web utilitzant React.js i tecnologies relacionades.",
          `Implementar disseny responsive i assegurar compatibilitat "cross-browser"`,
          "Participar en revisions de codi i donar feedback constructiu als altres desenvolupadors.",
          `Metodologia AGILE`,
        ],
      },
      {
        title: "Desenvolupador web.",
        company_name: "Freelance",
        icon: logo, // Assegura't de definir "logo" com a icona si és necessari
        iconBg: "#383E56",
        date: "Novembre 2022 - Actualment",
        points: [
          "Desenvolupar i mantenir aplicacions web utilitzant React.js i tecnologies relacionades.",
          `Implementar disseny responsive i assegurar compatibilitat "cross-browser"`,
          `Traslladar models de disseny a codi (Figma, Adobe, etc)`,
          "Especialització Frontend amb nocions de Backend (Nodejs)",
        ],
      },
    ],
  },
  castella: {
    title: "Qué he hecho hasta ahora",
    h2: "Experiencia laboral",
    experiences: [
      {
        title: "Técnico Economista",
        company_name: "T19 Estudis i Projectes S.L",
        icon: starbucks, // Assegura't de definir "starbucks" com a icona si és necessari
        iconBg: "#383E56",
        date: "Enero 2019 - Agosto 2022",
        points: [
          "Seguimiento presupuestarip",
          `Colaboración en el proyecto "Apropa Cultura" en el área de proyección económica y sostenibilidad`,
        ],
      },
      {
        title: "Bootcamp",
        company_name: "Barcelona Activa",
        icon: tesla, // Assegura't de definir "tesla" com a icona si és necessari
        iconBg: "#E6DEDD",
        date: "Febrero 2022 - Septiembre 2022",
        points: [
          "Desarrollar y mantener aplicaciones web utilizando React.js y tecnologías relacionadas.",
          `Implementar diseño responsive y asegurar compatibilidad "cross-browser`,
          "Participar en revisiones de código y dar feedback constructivo a otros desarrolladores.",
          `Metodología AGILE`,
        ],
      },
      {
        title: "Desarrollador web.",
        company_name: "Freelance",
        icon: logo, // Assegura't de definir "logo" com a icona si és necessari
        iconBg: "#383E56",
        date: "Noviembre 2022 - Actualmente",
        points: [
          "Desarrollar y mantener aplicaciones web utilizando React.js y tecnologías relacionadas.",
          `Participar en revisiones de código y dar feedback constructivo a otros desarrolladores.`,
          `Trasladar modelos de diseño a código (Figma, Adobe, etc)`,
          "Especialización Frontend con nociones de Backend (Nodejs)",
        ],
      },
    ],
  },
  angles: {
    title: "What I've done so far",
    h2: "Work Experience",
    experiences: [
      {
        title: "Economist",
        company_name: "T19 Estudis i Projectes S.L",
        icon: starbucks, // Assegura't de definir "starbucks" com a icona si és necessari
        iconBg: "#383E56",
        date: "January 2019 - August 2022",
        points: [
          "Budget monitoring",
          `Collaboration in the "Apropa Cultura" project in the area of ​​economic projection and sustainability`,
        ],
      },
      {
        title: "Bootcamp",
        company_name: "Barcelona Activa",
        icon: tesla, // Assegura't de definir "tesla" com a icona si és necessari
        iconBg: "#E6DEDD",
        date: "February 2022 - September 2022",
        points: [
          "Develop and maintain web applications using React.js and related technologies.",
          `Implement responsive design and ensure cross-browser compatibility"`,
          "Participate in code reviews and provide constructive feedback to other developers.",
          `AGILE methodology`,
        ],
      },
      {
        title: "Web Developer.",
        company_name: "Freelance",
        icon: logo, // Assegura't de definir "logo" com a icona si és necessari
        iconBg: "#383E56",
        date: "November 2022 - Present",
        points: [
          "Develop and maintain web applications using React.js and related technologies.",
          `Implement responsive design and ensure cross-browser compatibility"`,
          "Bring design models to code (Figma, Adobe, etc.)",
          "Frontend specialization with some Backend background (Nodejs)",
        ],
      },
    ],
  },
};
