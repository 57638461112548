export const contactData = {
  catala: {
    title: "Posem-nos en contacte",
    h2: "Contacte",
    namePlaceholder: "Com et dius?",
    nameInput: "El teu nom",
    emailPlaceholder: "Quin és el teu correu?",
    emailInput: "El teu correu",
    messagePlaceholder: "Quin és el teu missatge?",
    messageInput: "El teu missatge",
    loading: "Enviant...",
    loaded: "Enviar",
    successAltert: "Gràcies. Et contactaré el més aviat possible!",
    error: "Alguna cosa ha anat malament. Torna-ho a intentar.",
  },
  castella: {
    title: "Pongámonos en contacto",
    h2: "Contacto",
    namePlaceholder: "Tu nombre",
    nameInput: "Cómo te llamas?",
    emailPlaceholder: "Cuál es tu correo?",
    emailInput: "Tu correo",
    messagePlaceholder: "Cuál es el mensaje?",
    messageInput: "Tu mensaje",
    loading: "Enviando...",
    loaded: "Enviar",
    successAltert: "Gracias. Te contactaré lo más pronto posible!",
    error: "Alguna cosa ha ido mal. Inténtalo de nuevo.",
  },
  angles: {
    title: "Get in touch",
    h2: "Contact",
    nameIPlaceholder: "What's your name?",
    nameInput: "Your name",
    emailPlaceholder: "What's your email?",
    emailInput: "Your email",
    messagePlaceholder: "What's the message?",
    messageInput: "Your message",
    loading: "Sending...",
    loaded: "Send",
    successAltert: "Thank you. I will get back to you as soon as possible.",
    error: "Something went wrong. Please try again.",
  },
};
