import { motion } from "framer-motion";
import { useContext, useState, useEffect } from "react";
import { LanguageActivity } from "../context/LanguageActivity";
import { styles } from "../styles";
import { ComputersCanvas } from "./canvas";
import monitor from "../assets/monitor.jpg";
const Hero = () => {
  const { data } = useContext(LanguageActivity);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <section className={`relative w-full h-screen mx-auto`}>
      <div
        className={`absolute inset-0 top-[120px]  max-w-7xl mx-auto ${styles.paddingX} flex flex-row items-start gap-5`}
      >
        <div className="flex flex-col justify-center items-center mt-5">
          <div className="w-5 h-5 rounded-full bg-[#915EFF]" />
          <div className="w-1 sm:h-80 h-40 violet-gradient" />
        </div>

        {data.heroData !== undefined && (
          <div>
            <h1 className={`${styles.heroHeadText} text-white`}>
              {data.heroData.h1}
              <span className="text-[#915EFF]">&nbsp;{data.heroData.span}</span>
            </h1>
            <p className={`${styles.heroSubText} mt-2 text-white-100`}>
              {data.heroData.paragraph1} <br className="sm:block hidden" />
              {data.heroData.paragraph2}
            </p>
          </div>
        )}
      </div>
      {isMobile && (
        <img
          style={{ position: "absolute", bottom: "8rem" }}
          src={monitor}
          alt="monitor"
        />
      )}
      {!isMobile ? <ComputersCanvas /> : null}

      <div className="absolute xs:bottom-10 bottom-[5rem] w-full flex justify-center items-center">
        <a href="#about">
          <div className="w-[35px] h-[64px] rounded-3xl border-4 border-secondary flex justify-center items-start p-2">
            <motion.div
              animate={{
                y: [0, 24, 0],
              }}
              transition={{
                duration: 1.5,
                repeat: Infinity,
                repeatType: "loop",
              }}
              className="w-3 h-3 rounded-full bg-secondary mb-1"
            />
          </div>
        </a>
      </div>
    </section>
  );
};

export default Hero;
