import React, { useRef, useState, useContext } from "react";
import { motion } from "framer-motion";
import emailjs from "@emailjs/browser";
import { LanguageActivity } from "../context/LanguageActivity";

import { styles } from "../styles";
import { EarthCanvas } from "./canvas";
import { SectionWrapper } from "../hoc";
import { slideIn } from "../utils/motion";

const Contact = () => {
  const formRef = useRef();
  const [form, setForm] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [loading, setLoading] = useState(false);
  const { data } = useContext(LanguageActivity);
  const handleChange = (e) => {
    const { target } = e;
    const { name, value } = target;

    setForm({
      ...form,
      [name]: value,
    });
  };
  const serviceId = process.env.REACT_APP_FIRST_VARIABLE;
  const templateId = process.env.REACT_APP_SECOND_VARIABLE;
  const publicKey = process.env.REACT_APP_THIRD_VARIABLE;
  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    emailjs
      .send(
        serviceId,
        templateId,
        {
          from_name: form.name,
          to_name: "Portfolio",
          from_email: form.email,
          to_email: "ecastellsricart@gmail.com",
          message: form.message,
        },
        publicKey
      )
      .then(
        () => {
          setLoading(false);
          if (data.contactData !== undefined) {
            alert(data.contactData.successAltert);

            setForm({
              name: "",
              email: "",
              message: "",
            });
          }
        },
        (error) => {
          setLoading(false);
          console.error(error);
          if (data.ContactData !== undefined) {
            alert(data.contactData.error);
          }
        }
      );
  };

  return (
    <div
      className={`xl:mt-12 flex xl:flex-row flex-col-reverse gap-10 overflow-hidden`}
    >
      <motion.div
        variants={slideIn("left", "tween", 0.2, 1)}
        className="flex-[0.75] bg-black-100 p-8 rounded-2xl"
      >
        {data.contactData !== undefined && (
          <p className={styles.sectionSubText}>{data.contactData.title}</p>
        )}
        {data.contactData !== undefined && (
          <h3 className={styles.sectionHeadText}>{data.contactData.h2}</h3>
        )}

        <form
          ref={formRef}
          onSubmit={handleSubmit}
          className="mt-12 flex flex-col gap-8"
        >
          {data.contactData !== undefined && (
            <label className="flex flex-col">
              {data.contactData !== undefined && (
                <span className="text-white font-medium mb-4">
                  {data.contactData.nameInput}
                </span>
              )}
              <input
                type="text"
                name="name"
                value={form.name}
                onChange={handleChange}
                placeholder={data.contactData.namePlaceholder}
                className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>
          )}
          {data.contactData !== undefined && (
            <label className="flex flex-col">
              <span className="text-white font-medium mb-4">
                {data.contactData.emailInput}
              </span>

              <input
                type="email"
                name="email"
                value={form.email}
                onChange={handleChange}
                placeholder={data.contactData.emailPlaceholder}
                className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>
          )}
          {data.contactData !== undefined && (
            <label className="flex flex-col">
              <span className="text-white font-medium mb-4">
                {data.contactData.messageInput}
              </span>
              <textarea
                rows={7}
                name="message"
                value={form.message}
                onChange={handleChange}
                placeholder={data.contactData.messagePlaceholder}
                className="bg-tertiary py-4 px-6 placeholder:text-secondary text-white rounded-lg outline-none border-none font-medium"
              />
            </label>
          )}
          {data.contactData !== undefined && (
            <button
              type="submit"
              className="bg-tertiary py-3 px-8 rounded-xl outline-none w-fit text-white font-bold shadow-md shadow-primary"
            >
              {loading ? data.contactData.loading : data.contactData.loaded}
            </button>
          )}
        </form>
      </motion.div>

      <motion.div
        variants={slideIn("right", "tween", 0.2, 1)}
        className="xl:flex-1 xl:h-auto md:h-[550px] h-[350px]"
      >
        <EarthCanvas />
      </motion.div>
    </div>
  );
};

export default SectionWrapper(Contact, "contact");
