import { useContext, useState, useEffect } from "react";
import { Tilt } from "react-tilt";
import { motion } from "framer-motion";
import { LanguageActivity } from "../context/LanguageActivity";
import { styles } from "../styles";
import { SectionWrapper } from "../hoc";
import { fadeIn, textVariant } from "../utils/motion";

const ProjectCard = ({
  index,
  name,
  description,
  tags,
  image,
  isMobile,
  link,
}) => {
  function redirectToNewPage() {
    const newWindow = window.open(link);
    newWindow.focus(); // Assegura't que la nova finestra estigui enfocada
  }
  return (
    <motion.div variants={fadeIn("up", "spring", index * 0.5, 0.75)}>
      <Tilt
        options={{
          max: 45,
          scale: 1,
          speed: 450,
        }}
        className={
          isMobile
            ? "bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full"
            : "bg-tertiary p-5 rounded-2xl sm:w-[360px] w-full min-h-[505px] relative"
        }
      >
        <div
          className=" w-full h-[230px]"
          onClick={() => {
            redirectToNewPage();
          }}
        >
          <img
            src={image}
            alt="project_image"
            className="w-full h-full object-cover rounded-2xl"
          />
        </div>
        <div className="mt-5">
          <h3 className="text-white font-bold text-[24px]">{name}</h3>
          <p className="mt-2 text-secondary text-[14px]">{description}</p>
        </div>
        <div
          className={
            !isMobile
              ? "mt-4 flex flex-wrap gap-2 absolute bottom-[25px]"
              : "mt-4 flex flex-wrap gap-2"
          }
        >
          {tags.map((tag) => (
            <p
              key={`${name}-${tag.name}`}
              className={`text-[14px] ${tag.color}`}
            >
              #{tag.name}
            </p>
          ))}
        </div>
      </Tilt>
    </motion.div>
  );
};

const Works = () => {
  const { data } = useContext(LanguageActivity);
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    // Add a listener for changes to the screen size
    const mediaQuery = window.matchMedia("(max-width: 500px)");

    // Set the initial value of the `isMobile` state variable
    setIsMobile(mediaQuery.matches);

    // Define a callback function to handle changes to the media query
    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    // Add the callback function as a listener for changes to the media query
    mediaQuery.addEventListener("change", handleMediaQueryChange);

    // Remove the listener when the component is unmounted
    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);
  return (
    <>
      <motion.div variants={textVariant()}>
        {data.worksData !== undefined && (
          <p className={`${styles.sectionSubText} `}>{data.worksData.title}</p>
        )}
        {data.worksData !== undefined && (
          <h2 className={`${styles.sectionHeadText}`}>{data.worksData.h2}</h2>
        )}
      </motion.div>

      {data.worksData !== undefined && (
        <div className="w-full flex">
          <motion.p
            variants={fadeIn("", "", 0.1, 1)}
            className="mt-3 text-secondary text-[17px] max-w-3xl leading-[30px]"
          >
            {data.worksData.paragraph}
          </motion.p>
        </div>
      )}

      <div className="mt-20 flex flex-wrap gap-7">
        {data.worksData !== undefined &&
          data.worksData.projects.map((project, index) => (
            <ProjectCard
              key={`project-${index}`}
              index={index}
              {...project}
              isMobile={isMobile}
              link={project.link}
            />
          ))}
      </div>
    </>
  );
};

export default SectionWrapper(Works, "");
