import { mobile, backend, creator, web } from "../assets";
export const aboutData = {
  catala: {
    title: "Introducció",
    h2: "Sobre Mi",
    paragraph:
      "Desenvolupador web meticulós amb més de 2 anys d'experiència en Front End amb capacitat per emprendre tant projectes individuals com col·lectius. Apassionat del disseny responsive i un ferm defensor de l'enfoc mobile-first. M'agrada transformar el disseny d'UX/UI en aplicacions escalables, boniques i eficients.",
    services: [
      {
        title: "Frontend",
        icon: web,
      },
      {
        title: "Responsive",
        icon: mobile,
      },
      {
        title: "Eficient",
        icon: backend,
      },
      {
        title: "Escalable",
        icon: creator,
      },
    ],
  },
  castella: {
    title: "Introducción",
    h2: "Sobre Mí",
    paragraph:
      "Desarrollador web meticuloso con más de 2 años de experiencia en Front End con capacidad para llevar a cabo tanto proyectos individuales como colectivos. Apasionado del diseño de sitios web responsive y un firme defensor del enfoque mobile-first. Me gusta transformar el diseño de UX/UI en aplicaciones escalables, hermosas y eficientes.",
    services: [
      {
        title: "Frontend",
        icon: web,
      },
      {
        title: "Responsive",
        icon: mobile,
      },
      {
        title: "Eficiente",
        icon: backend,
      },
      {
        title: "Escalable",
        icon: creator,
      },
    ],
  },
  angles: {
    title: "Introduction",
    h2: "Overview",
    paragraph:
      "Meticulous web developer with over 2 years of front end experience with the ability to undertake both individual and collective projects. Passionate about responsive website design and a firm believer in the mobile-first approach. Love to transform UX/UI design into scalable, beautiful and efficient applications.",
    services: [
      {
        title: "Frontend",
        icon: web,
      },
      {
        title: "Responsive",
        icon: mobile,
      },
      {
        title: "Efficient",
        icon: backend,
      },
      {
        title: "Scalable",
        icon: creator,
      },
    ],
  },
};
