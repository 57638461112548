import chess from "../assets/chess.png";
import gospel from "../assets/gospel.png";
import mercantic from "../assets/mercantic.jpg";

export const worksData = {
  catala: {
    title: "Els meus treballs",
    h2: "Projectes.",
    paragraph:
      "Els següents projectes mostren les meves habilitats i experiència a través d'exemples reals de la meva feina. Cada projecte està breument descrit. Reflexen la capacitat per resoldre problemes complexos, treballar amb diferents tecnologies i gestionar projectes de manera efectiva.",
    projects: [
      {
        name: "Cosmic Chess",
        description:
          "Joc d'escacs clàssic contra una IA. La dificultat es pot graduar a tres nivells. A més a més de la funcionalitat descrita, també es pot reiniciar la partida. ",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "typescript",
            color: "green-text-gradient",
          },
          {
            name: "sass",
            color: "pink-text-gradient",
          },
        ],
        image: chess,
        link: "https://space-chess.netlify.app/",
      },
      {
        name: "Gòspel Sense Fronteres",
        description:
          "Projecte Gòspel Sense Fronteres. Disseny i programació de la pàgina web del grup de corals. ",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "nodejs",
            color: "green-text-gradient",
          },
          {
            name: "styled-components",
            color: "pink-text-gradient",
          },
        ],
        image: gospel,
        link: "https://gospelsensefronteres.cat/",
      },
      {
        name: "Mercantic",
        description:
          "Pàgina web de Mercantic, mercat d'antiguitats a Sant Cugat. Informació sobre el mercat i els seus espais. Consta d'una agenda mensual on es pot filtrar per etiquetes. En 3 idiomes i totalment responsive.",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "express",
            color: "green-text-gradient",
          },
          {
            name: "figma",
            color: "pink-text-gradient",
          },
        ],
        image: mercantic,
        link: "https://mercantic.com/",
      },
    ],
  },
  castella: {
    title: "Mis trabajos",
    h2: "Proyectos.",
    paragraph:
      "Los siguientes proyectos muestran mis habilidades y experiencia a través de ejemplos reales de mi trabajo. Cada proyecto está brevemente descrito. Reflejan la capacidad para resolver problemas complejos, trabajar con diferentes tecnologías y gestionar proyectos de forma efectiva.",
    projects: [
      {
        name: "Cosmic Chess",
        description:
          "Juego de ajedrez clásico contra una IA. La dificultad puede graduarse a tres niveles. Además de la funcionalidad descrita, también puede reiniciarse la partida.",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "typescript",
            color: "green-text-gradient",
          },
          {
            name: "sass",
            color: "pink-text-gradient",
          },
        ],
        image: chess,
        link: "https://space-chess.netlify.app/",
      },
      {
        name: "Gòspel Sense Fronteres",
        description:
          "Proyecto web sobre el grupo de corales `Gòspel Sense Fronteres`. Información de las corales, así como actualizaciones sobre las siguientes actuaciones.",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "nodejs",
            color: "green-text-gradient",
          },
          {
            name: "styled-components",
            color: "pink-text-gradient",
          },
        ],
        image: gospel,
        link: "https://gospelsensefronteres.cat/",
      },
      {
        name: "Mercantic",
        description:
          "Página web de Mercantic, mercado de antigüedades en Sant Cugat. Información sobre el mercado y sus diferentes espacios. Consta de una agenda mensual en la que se puede filtrar por etiquetas. 3 idiomas y totalmente responsive.",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "react-router",
            color: "green-text-gradient",
          },
          {
            name: "figma",
            color: "pink-text-gradient",
          },
        ],
        image: mercantic,
        link: "https://mercantic.com/",
      },
    ],
  },
  angles: {
    title: "My Works",
    h2: "Projects.",
    paragraph:
      "The following projects showcase my skills and experience through real examples of my work. Each project is briefly described. It reflects the ability to solve complex problems, work with different technologies and manage projects effectively.",
    projects: [
      {
        name: "Cosmic Chess",
        description:
          "Classic chess game against an AI. The difficulty can be graduated in three levels. In addition to the functionality described, the game can also be restarted. ",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "typescript",
            color: "green-text-gradient",
          },
          {
            name: "sass",
            color: "pink-text-gradient",
          },
        ],
        image: chess,
        link: "https://space-chess.netlify.app/",
      },
      {
        name: "Gòspel Sense Fronteres",
        description: "Website of the choir group Gòspel Sense Fronteres.",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "nodejs",
            color: "green-text-gradient",
          },
          {
            name: "styled-components",
            color: "pink-text-gradient",
          },
        ],
        image: gospel,
        link: "https://gospelsensefronteres.cat/",
      },
      {
        name: "Mercantic",
        description:
          "Mercantic website, antiques market in Sant Cugat. Information about the market and its different parts. Has a monthly agenda that can be filtered by tags. In 3 languages ​​and fully responsive.",
        tags: [
          {
            name: "react",
            color: "blue-text-gradient",
          },
          {
            name: "react-router",
            color: "green-text-gradient",
          },
          {
            name: "figma",
            color: "pink-text-gradient",
          },
        ],
        image: mercantic,
        link: "https://mercantic.com/",
      },
    ],
  },
};
