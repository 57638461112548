export const navLinks = {
  angles: [
    {
      id: "about",
      title: "About",
    },
    {
      id: "work",
      title: "Work",
    },
    {
      id: "contact",
      title: "Contact",
    },
  ],
  castella: [
    {
      id: "about",
      title: "About",
    },
    {
      id: "work",
      title: "Trabajo",
    },
    {
      id: "contact",
      title: "Contact",
    },
  ],
  catala: [
    {
      id: "about",
      title: "Sobre Mi",
    },
    {
      id: "work",
      title: "Feina",
    },
    {
      id: "contact",
      title: "Contacte",
    },
  ],
};
