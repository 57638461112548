import React, { createContext, useState, useEffect } from "react";
import { heroData } from "../data/HeroData";
import { aboutData } from "../data/AboutData";
import { experienceData } from "../data/ExperienceData";
import { worksData } from "../data/WorksData";
import { contactData } from "../data/ContactData";
import { navLinks } from "../data/NavbarData";
export const LanguageActivity = createContext();

export const LanguageActivityProvider = ({ children }) => {
  const [languageSelected, setLanguageSelected] = useState({ catala: true });
  const [data, setData] = useState({});

  useEffect(() => {
    if (languageSelected) {
      if (languageSelected.catala === true) {
        setData({
          heroData: heroData.catala,
          aboutData: aboutData.catala,
          experienceData: experienceData.catala,
          worksData: worksData.catala,
          contactData: contactData.catala,
          navbarData: navLinks.catala,
        });
      } else if (languageSelected.castella === true) {
        setData({
          heroData: heroData.castella,
          aboutData: aboutData.castella,
          experienceData: experienceData.castella,
          worksData: worksData.castella,
          contactData: contactData.castella,
          navbarData: navLinks.castella,
        });
      } else if (languageSelected.angles === true) {
        setData({
          heroData: heroData.angles,
          aboutData: aboutData.angles,
          experienceData: experienceData.angles,
          worksData: worksData.angles,
          contactData: contactData.angles,
          navbarData: navLinks.angles,
        });
      }
    }
  }, [languageSelected]);
  useEffect(() => {
    if (localStorage.getItem("language")) {
      setLanguageSelected(JSON.parse(localStorage.getItem("language")));
    } else {
      setLanguageSelected({
        catala: true,
        castella: false,
        angles: false,
      });
    }
    //eslint-disable-next-line
  }, []);
  return (
    <LanguageActivity.Provider
      value={{
        languageSelected,
        setLanguageSelected,
        data,
        setData,
      }}
    >
      {children}
    </LanguageActivity.Provider>
  );
};
