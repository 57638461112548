export const heroData = {
  catala: {
    h1: "Hola, sóc",
    span: "l'Eugeni",
    paragraph1: "Desenvolupament i disseny ",
    paragraph2: "web, interfícies d'usuari i aplicacions",
  },
  castella: {
    h1: "Hola, soy",
    span: "Eugeni",
    paragraph1: "Desarrollo y diseño ",
    paragraph2: "web, interfícies de usuario y aplicaciones",
  },
  angles: {
    h1: "Hi, I'm",
    span: "Eugeni",
    paragraph1: "Web development and design, user",
    paragraph2: " interfaces and applications",
  },
};
