import { useState, useEffect } from "react";

import { BallCanvas } from "./canvas";
import { SectionWrapper } from "../hoc";
import { technologies } from "../constants";

const Tech = () => {
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const mediaQuery = window.matchMedia("(max-width: 500px)");
    setIsMobile(mediaQuery.matches);

    const handleMediaQueryChange = (event) => {
      setIsMobile(event.matches);
    };

    mediaQuery.addEventListener("change", handleMediaQueryChange);

    return () => {
      mediaQuery.removeEventListener("change", handleMediaQueryChange);
    };
  }, []);

  return (
    <div className="flex flex-row flex-wrap justify-center gap-10">
      {technologies.map((technology, index) => (
        <div
          className="w-28 h-28"
          key={technology.name}
          onMouseOver={(e) => {
            if (isMobile) {
              e.currentTarget.style.animation = "shake 1s infinite";
              // Aplicar l'animació només quan és isMobile
            }
          }}
          onMouseOut={(e) => {
            if (isMobile) {
              e.currentTarget.style.animation = "none";
            }

            // Eliminar l'animació quan deixes de fer hover
          }}
          style={
            isMobile
              ? {
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  backgroundColor: "#f3f3f3",
                  borderRadius: "100%",
                  height: "70px",
                  width: "70px",
                }
              : {}
          }
        >
          <BallCanvas icon={technology.icon} isMobile={isMobile} />
        </div>
      ))}
    </div>
  );
};

export default SectionWrapper(Tech, "");
